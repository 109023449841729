@import "../node_modules/bulma/bulma.sass";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.column-hover {
  margin-top: 0.75rem;
}
.column-hover:hover {
  background-color: #f0f0f0;
}

input[type=radio] {
  margin-right: 5px;
}

@keyframes slideIn {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

.device-item {
  animation: slideIn 0.5s;
  z-index: 0;
}
